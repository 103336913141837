import React, {FC, useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Field, Form, Formik, FormikProps} from 'formik';
import {useSelector} from 'react-redux';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import {CreateOrganizationRequestTypes} from 'appRedux/actions/organization/types';
import {RootReducer} from 'appRedux/reducers';
import {LanguageItemTypes} from 'appRedux/actions/admin/types';

import {RouteContext} from 'contexts/route/context';

import MdxEditorField from 'components/MdxEditorField/MdxEditorField';
import {CreateOrganizationValidationSchema} from 'components/Forms/OrganizationForm/validation';
import {getTranslationByKeyword} from 'components/Forms/OrganizationForm/helper';
import AgentSaveButton from 'components/AgentScreenComponents/_buttons/AgentSaveButton';
import FormikTextInput from 'components/AgentScreenComponents/_form/FormBuilderTextInput';
import FormBuilderCheckboxReverse from 'components/AgentScreenComponents/_form/FormBuilderCheckboxReverse';

import ChangeDomainNameButton from 'pages/admin/organizations/OrganizationList/ChangeDomainNameButton';

interface OrganizationFormType {
    initialValues: CreateOrganizationRequestTypes;
    onSubmitClicked: (values: CreateOrganizationRequestTypes) => void;
    isLoading: boolean;
    isCreate?: boolean;
}

const OrganizationForm: FC<OrganizationFormType> = ({initialValues, isLoading, isCreate, onSubmitClicked}) => {
    const [t] = useTranslation();

    const {isSuperAdminPage} = useContext(RouteContext);

    const {
        admin: {languageList, organizationTranslations},
    } = useSelector<RootReducer>((state: RootReducer) => state) as RootReducer;

    const {id, domainName} = initialValues;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={CreateOrganizationValidationSchema}
            onSubmit={(values, {setErrors}) => {
                onSubmitClicked({
                    ...values,
                    setErrors,
                });
            }}
        >
            {(formik: FormikProps<CreateOrganizationRequestTypes>) => {
                return (
                    <Form>
                        <Box>
                            <Field
                                name="name"
                                label={t('superadmin.organizations.organizationName')}
                                type="text"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <Field
                                name="email"
                                label={t('superadmin.organizations.organizationEmail')}
                                type="email"
                                component={FormikTextInput}
                            />
                        </Box>
                        {isSuperAdminPage && (
                            <FormControl sx={{mt: 2}} required fullWidth>
                                <InputLabel>{t('superadmin.organizations.language')}</InputLabel>
                                <Select
                                    label={t('superadmin.organizations.language')}
                                    name={'language'}
                                    value={formik.values.language}
                                    onChange={e => {
                                        formik.handleChange(e);
                                        if (!isCreate) {
                                            const languageId = Number(e.target.value);
                                            const filteredTranslations = organizationTranslations.filter(
                                                item => item.language === languageId,
                                            );
                                            formik.setFieldValue(
                                                'name',
                                                getTranslationByKeyword(
                                                    filteredTranslations,
                                                    'name',
                                                    formik.values.name,
                                                ),
                                            );
                                            formik.setFieldValue(
                                                'description',
                                                getTranslationByKeyword(
                                                    filteredTranslations,
                                                    'description',
                                                    formik.values.description,
                                                ),
                                            );
                                            formik.setFieldValue(
                                                'privacyPolicy',
                                                getTranslationByKeyword(
                                                    filteredTranslations,
                                                    'privacyPolicy',
                                                    formik.values.privacyPolicy,
                                                ),
                                            );
                                        }
                                    }}
                                    IconComponent={KeyboardArrowDownIcon}
                                    fullWidth
                                >
                                    {languageList &&
                                        languageList.map((item: LanguageItemTypes, index: number) => {
                                            return (
                                                <MenuItem key={`workflow-${index}`} value={item.id}>
                                                    <Typography sx={{fontWeight: 600}}>{item.lang}</Typography>
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        )}
                        <Box>
                            <MdxEditorField
                                name="description"
                                value={formik.values.description ? String(formik.values.description) : ''}
                                label={t('superadmin.organizations.description')}
                            />
                        </Box>
                        {isCreate && (
                            <Box>
                                <Field
                                    name="domainName"
                                    label={t('superadmin.organizations.domainName')}
                                    type="text"
                                    component={FormikTextInput}
                                />
                            </Box>
                        )}
                        <Box>
                            <Field
                                name="address"
                                label={t('superadmin.organizations.address')}
                                type="textarea"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <MdxEditorField
                                name="privacyPolicy"
                                value={formik.values.privacyPolicy ? String(formik.values.privacyPolicy) : ''}
                                label={t('superadmin.organizations.privacyPolicy')}
                            />
                        </Box>
                        <Box>
                            <Field
                                name="pdfExportHeader"
                                label={t('superadmin.organizations.pdfExportHeader')}
                                type="text"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <Field
                                name="pdfExportFooter"
                                label={t('superadmin.organizations.pdfExportFooter')}
                                type="text"
                                component={FormikTextInput}
                            />
                        </Box>
                        <Box>
                            <Field
                                name="logoutIdleTime"
                                label={t('superadmin.organizations.logoutIdleTime')}
                                placeholder={t('superadmin.organizations.logoutIdleTime')}
                                type="number"
                                InputProps={{inputProps: {min: 10, max: 60}}}
                                component={FormikTextInput}
                                required
                            />
                        </Box>
                        {isSuperAdminPage && (
                            <Box sx={{mt: 3}}>
                                <Box sx={{mb: 2, mt: 1}}>
                                    <Field
                                        type="checkbox"
                                        name="isClosed"
                                        label={t('superadmin.organizations.closedOrganization')}
                                        component={FormBuilderCheckboxReverse}
                                    />
                                </Box>
                                <Box sx={{mb: 2, mt: 1}}>
                                    <Field
                                        type="checkbox"
                                        name="isEncryptionPossible"
                                        label={t('superadmin.organizations.caseEncryptionPossible')}
                                        component={FormBuilderCheckboxReverse}
                                    />
                                </Box>
                                <Box sx={{mb: 2, mt: 1}}>
                                    <Field
                                        type="checkbox"
                                        name="isInviteRegistration"
                                        label={t('superadmin.organizations.registrationByInviteIsPossible')}
                                        component={FormBuilderCheckboxReverse}
                                    />
                                </Box>
                                <Box sx={{mb: 2, mt: 1}}>
                                    <Field
                                        type="checkbox"
                                        name="isGoogleTranslationAllowed"
                                        label={t('superadmin.organizations.isGoogleTranslationAllowed')}
                                        component={FormBuilderCheckboxReverse}
                                    />
                                </Box>
                                <Box sx={{mb: 2, mt: 1}}>
                                    <Field
                                        type="checkbox"
                                        name="isExportAllowed"
                                        label={t('superadmin.organizations.requesterCasesExportAllowed')}
                                        component={FormBuilderCheckboxReverse}
                                    />
                                </Box>
                                <Box sx={{mb: 2, mt: 1}}>
                                    <Field
                                        type="checkbox"
                                        name="isRequesterEmailDisplayingAllowed"
                                        label={t('superadmin.organizations.requesterEmailDisplayingAllowed')}
                                        component={FormBuilderCheckboxReverse}
                                    />
                                </Box>
                                <Box sx={{mb: 2, mt: 1}}>
                                    <Field
                                        type="checkbox"
                                        name="isMultipleRequestPossible"
                                        label={t('superadmin.organizations.multipleRequestPossible')}
                                        component={FormBuilderCheckboxReverse}
                                    />
                                </Box>
                                <Box sx={{mb: 2, mt: 1}}>
                                    <Field
                                        type="checkbox"
                                        name="isRequesterModeEnabled"
                                        label={t('superadmin.organizations.requesterModeEnabled')}
                                        component={FormBuilderCheckboxReverse}
                                    />
                                </Box>
                                <Box sx={{mb: 3, mt: 1}}>
                                    <Field
                                        type="checkbox"
                                        name="isAutoLoginSso"
                                        label={t('superadmin.organizations.autoLoginSso')}
                                        component={FormBuilderCheckboxReverse}
                                    />
                                </Box>
                            </Box>
                        )}
                        <Box>
                            <AgentSaveButton
                                isLoading={isLoading}
                                isSubmit={true}
                                title={t(
                                    isCreate
                                        ? 'superadmin.organizations.addNewOrganization'
                                        : 'common.buttons.saveButton',
                                )}
                            />
                            {id && isSuperAdminPage && <ChangeDomainNameButton id={id} domainName={domainName ?? ''} />}
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default OrganizationForm;
