import {call, fork, put, take} from 'redux-saga/effects';
import get from 'lodash/get';
import {http} from 'services/http';

import {
    GET_ALL_REQUESTER_CASES,
    UPDATE_REQUESTER_CASE,
    CREATE_REQUESTER_CASE,
    UPDATE_CHECKED_SECTIONS,
    GET_REQUESTER_CASE,
    GET_REQUESTER_CASES_STATISTIC,
    getRequesterCasesStatistic,
    UPLOAD_REQUESTER_DOCUMENT,
    DELETE_REQUESTER_DOCUMENT,
    UPLOAD_REQUESTER_SIGNATURE,
    REQUESTER_PREPARED_STATUS,
    REQUEST_CASE_ASSIGN_AGENT,
    REQUEST_CASE_CHANGE_STATUS,
    REQUEST_CASE_CHAT_ACTIVATE,
    REQUEST_CASE_PINNING,
    REQUEST_CASE_ADD_TAG,
    REQUEST_CASE_REMOVE_TAG,
    REQUEST_CASE_ARCHIVE,
    REQUEST_CASE_QUESTION,
    REQUEST_CASE_ACTIVITIES,
    REQUEST_CASE_ACTIVITIES_EXPORT,
    REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE,
    REQUESTER_CASE_EMAIL_REQUEST,
    UPDATE_STATUS_CHANGE_APPROVE_REQUEST,
    UNSET_STATUS_CHANGE_APPROVE_REQUEST,
    DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS,
    getAllRequesterCases,
    getRequesterCase,
    createRequesterCase,
    updateRequesterCase,
    updateCheckedSections,
    uploadRequesterCase,
    deleteRequesterCase,
    requesterPreparedStatus,
    requestCaseAssignAgent,
    requestCaseChangeStatus,
    requestCaseChatActivate,
    requestCasePinning,
    addRequesterTag,
    deleteRequesterTag,
    requesterArchiveDownload,
    requestCaseQuestion,
    getRequestCaseActivities,
    exportRequestCaseActivities,
    getRequestCaseAttachmentsForArchive,
    CREATE_CASE_WATCHER,
    createCaseWatcher,
    DELETE_CASE_WATCHER,
    deleteCaseWatcher,
    requesterCaseEmailRequest,
    editRequesterCase,
    EDIT_REQUESTER_DOCUMENT,
    uploadRequesterSignature,
    DELETE_REQUESTER_SIGNATURE,
    deleteRequesterSignature,
    updateStatusChangeApproveRequest,
    unsetStatusChangeApproveRequest,
    deleteAllStatusChangeApproveRequests,
    CREATE_ADDITIONAL_FORM_INVITE,
    createAdditionalFormInvite,
    DELETE_ADDITIONAL_FORM_INVITE,
    deleteAdditionalFormInvite,
    IGNORE_CASE_HAS_TASK,
    ignoreCaseHasTask,
    CREATE_SUBFORM_REQUEST,
    createSubFormRequest,
    SUBMIT_SUBFORM_REQUEST,
    submitSubFormRequest,
    DELETE_SUBFORM_REQUEST,
    deleteSubFormRequest,
} from 'appRedux/actions/requestCase';
import {
    RequesterCaseResponseTypes,
    RequesterCaseTypes,
    RequesterCaseGettingTypes,
    RequesterCaseUpdatingTypes,
    RequestCaseTagRequestTypes,
    UpdateCheckedSectionRequestTypes,
    UploadRequesterCaseTypes,
    DeleteRequesterDocumentTypes,
    RequesterPreparedStatusType,
    RequestCaseChangeStatusType,
    RequestCaseAssignAgentType,
    RequesterAllCaseTypes,
    EmailSendingResponseType,
    RequestCaseChatActivateRequestTypes,
    RequesterCaseCreateRequestTypes,
    RequesterCaseCreateResponseTypes,
    RequesterCasesStatisticTypes,
    RequesterCaseTagResponseTypes,
    RequesterCaseDownloadArchiveTypes,
    UpdateCheckedSectionResponseTypes,
    RequesterCaseActivitiesRequestTypes,
    RequesterCaseActivitiesResponseTypes,
    RequestCaseQuestionRequestTypes,
    RequestCaseQuestionResponseTypes,
    RequesterCaseActivitiesTypes,
    RequesterCaseAttachmentsForArchiveResponseTypes,
    RequesterCaseActivitiesExportRequestTypes,
    CaseWatcherCreatingTypes,
    CaseWatcherResponseTypes,
    CaseWatcherRemovingTypes,
    RequesterCaseEmailRequestTypes,
    RequesterCaseEmailResponseTypes,
    EditRequesterDocumentTypes,
    UploadRequesterSignatureTypes,
    DeleteRequesterSignatureTypes,
    StatusChangeApproveUpdateRequestType,
    UnsetStatusChangeApproveRequestType,
    DeleteAllStatusChangeApproveRequestType,
    StatusChangeApproveResponseType,
    InviteAdditionalFormCreateRequestTypes,
    InviteAdditionalFormDeleteRequestTypes,
    InviteAdditionalFormResponseTypes,
    SubFormRequestsResponseTypes,
    SubFormRequestDeleteRequestTypes,
    SubFormRequestSubmittingRequestTypes,
    CaseHasTaskIgnoringTypes,
    CaseHasTaskResponseTypes,
} from 'appRedux/actions/requestCase/types';
import {
    RequesterDocumentUploadResponseType,
    RequesterSignatureUploadResponseType,
} from 'appRedux/actions/profile/types';
import {RequesterCaseStatisticRequestTypes} from 'appRedux/actions/admin/types';

import {ALERT_TYPE_ERROR, ALERT_TYPE_SUCCESS} from 'config/index';
import {
    ERROR_OUTDATED_INFORMATION,
    ERROR_SECTION_ALREADY_CHECKED,
    ERROR_MAXIMAL_FILES_NUMBER,
    ERROR_MESSAGE_WRONG_DOCUMENT_SIZE,
    ERROR_MESSAGE_WRONG_DOCUMENT_TYPE,
    ERROR_UNAVAILABLE_WORK_WITH_FORM,
    ERROR_INFECTED_FILE,
} from 'config/errors';

function* watchGetAllRequesterCases() {
    while (true) {
        const {
            payload: {id},
        }: IActionType<RequesterCaseGettingTypes> = yield take(GET_ALL_REQUESTER_CASES.REQUEST);
        try {
            const data: RequesterAllCaseTypes[] = yield call(http, `user/${id}/cases`, {
                method: 'GET',
            });
            if (data) {
                yield put(getAllRequesterCases.success(data));
            } else {
                yield put(getAllRequesterCases.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getAllRequesterCases.error({message: String(e)}));
        }
    }
}

function* watchGetRequesterCase() {
    while (true) {
        const {
            payload: {id},
        }: IActionType<RequesterCaseGettingTypes> = yield take(GET_REQUESTER_CASE.REQUEST);
        try {
            const data: RequesterCaseTypes = yield call(http, `case/${id}/information`, {
                method: 'GET',
            });
            const caseInformation = get(data, ['case'], {});
            const result = get(data, ['case', 'result'], null);
            const checkedSections = get(data, 'checkedSections', []);
            const chatFiles = get(data, 'chatFiles', []);
            const notReadMessages = get(data, 'notReadMessages', 0);
            const translations = get(data, 'translations', []);
            const files = get(data, 'files', []);
            const tags = get(data, 'tags', []);
            const watchers = get(data, ['watchers'], {});
            const signatures = get(data, 'signatures', []);
            const invites = get(data, 'invites', []);
            const subFormRequests = get(data, 'subFormRequests', []);
            const tasks = get(data, 'tasks', []);
            yield put(
                getRequesterCase.success({
                    ...caseInformation,
                    result: result ? JSON.parse(result) : null,
                    checkedSections,
                    chatFiles,
                    notReadMessages,
                    translations,
                    files,
                    tags,
                    watchers,
                    signatures,
                    invites,
                    subFormRequests,
                    tasks,
                }),
            );
        } catch (e) {
            yield put(getRequesterCase.error({message: String(e)}));
        }
    }
}

function* watchCreateRequestCase() {
    while (true) {
        const {
            payload: {formId, callback, invite, showAlert},
        }: IActionType<RequesterCaseCreateRequestTypes> = yield take(CREATE_REQUESTER_CASE.REQUEST);
        try {
            const data: RequesterCaseCreateResponseTypes = yield call(http, `case/${formId}/create`, {
                method: 'POST',
                body: JSON.stringify({invite}),
            });
            if (data.success) {
                yield put(createRequesterCase.success(data));
                callback && callback(data.caseId);
            } else if (data.errors) {
                yield put(createRequesterCase.error({message: data.errors}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            } else {
                yield put(createRequesterCase.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createRequesterCase.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUpdateRequesterCase() {
    while (true) {
        const {
            payload: {id, callback, result, sectionId, pageId, toggleSectionChecked, showAlert},
        }: IActionType<RequesterCaseUpdatingTypes> = yield take(UPDATE_REQUESTER_CASE.REQUEST);
        try {
            const data: RequesterCaseTypes = yield call(http, `case/${id}/update`, {
                method: 'POST',
                body: JSON.stringify({
                    result: JSON.stringify(result),
                    sectionId,
                    pageId,
                }),
            });

            const resultData = get(data, ['case', 'result'], null);

            if (resultData) {
                yield put(updateRequesterCase.success(JSON.parse(resultData)));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors === ERROR_SECTION_ALREADY_CHECKED) {
                yield put(updateRequesterCase.error({message: ERROR_SECTION_ALREADY_CHECKED}));
                toggleSectionChecked && toggleSectionChecked();
            } else {
                yield put(updateRequesterCase.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateRequesterCase.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUpdateCheckedSections() {
    while (true) {
        const {
            payload: {id, agentId, sectionId, readAt, showAlert, toggleOutdatedInfo},
        }: IActionType<UpdateCheckedSectionRequestTypes> = yield take(UPDATE_CHECKED_SECTIONS.REQUEST);
        try {
            const data: UpdateCheckedSectionResponseTypes = yield call(http, `agent/${id}/checked`, {
                method: 'POST',
                body: JSON.stringify({
                    agent: agentId,
                    section: sectionId,
                    readAt,
                }),
            });
            if (data.errors) {
                if (data.errors === ERROR_OUTDATED_INFORMATION) {
                    yield put(updateCheckedSections.error({message: ERROR_OUTDATED_INFORMATION}));
                    toggleOutdatedInfo && toggleOutdatedInfo();
                } else {
                    yield put(updateCheckedSections.error({message: 'messages.error.somethingWentWrong'}));
                    showAlert && showAlert(ALERT_TYPE_ERROR);
                }
            } else {
                yield put(updateCheckedSections.success(data.checkedSections));
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            }
        } catch (e) {
            yield put(updateCheckedSections.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRequesterPreparedStatus() {
    while (true) {
        const {
            payload: {id},
        }: IActionType<RequesterPreparedStatusType> = yield take(REQUESTER_PREPARED_STATUS.REQUEST);
        try {
            const data: string[] = yield call(http, `case/${id}/prepared`, {
                method: 'GET',
            });
            const resultData = get(data, ['case', 'result'], null);
            if (resultData) {
                yield put(requesterPreparedStatus.success(JSON.parse(resultData)));
            } else {
                yield put(requesterPreparedStatus.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(requesterPreparedStatus.error({message: String(e)}));
        }
    }
}

function* watchUploadFile() {
    while (true) {
        const {
            payload: {
                userId,
                file,
                fileName,
                encryptPrefix,
                callbackError,
                callbackInfectedFileError,
                formId,
                fieldId,
                description,
                caseId,
                thumb,
                sectionId,
                setErrors,
                callback,
                showAlert,
            },
        }: IActionType<UploadRequesterCaseTypes> = yield take(UPLOAD_REQUESTER_DOCUMENT.REQUEST);
        try {
            const formData = new FormData();
            formData.append('media', file, fileName);
            formData.append('description', description);
            if (encryptPrefix) formData.append('encryptPrefix', encryptPrefix);
            if (thumb) formData.append('thumb', thumb);
            formData.append('caseId', String(caseId));
            formData.append('formId', String(formId));
            formData.append('sectionId', String(sectionId));
            formData.append('fieldId', String(fieldId));
            const data: RequesterDocumentUploadResponseType = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `user/case/${userId}`,
                {
                    method: 'POST',
                    body: formData,
                },
            );

            const infectedFileMessage = get(data, ['errors', 'message'], null);

            console.log('data', data);
            if (data.success) {
                yield put(
                    uploadRequesterCase.success({
                        files: data.results,
                    }),
                );
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors === ERROR_SECTION_ALREADY_CHECKED) {
                yield put(uploadRequesterCase.error({message: ERROR_SECTION_ALREADY_CHECKED}));
                callbackError && callbackError();
            } else if (data.errors === ERROR_MAXIMAL_FILES_NUMBER) {
                yield put(uploadRequesterCase.error({message: ERROR_MAXIMAL_FILES_NUMBER}));
                showAlert && showAlert(ALERT_TYPE_ERROR, ERROR_MAXIMAL_FILES_NUMBER);
            } else if (data.errors === ERROR_MESSAGE_WRONG_DOCUMENT_SIZE) {
                yield put(uploadRequesterCase.error({message: ERROR_MESSAGE_WRONG_DOCUMENT_SIZE}));
                showAlert && showAlert(ALERT_TYPE_ERROR, ERROR_MESSAGE_WRONG_DOCUMENT_SIZE);
            } else if (data.errors === ERROR_MESSAGE_WRONG_DOCUMENT_TYPE) {
                yield put(uploadRequesterCase.error({message: 'wrongDocumentTypeMessage'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, 'wrongDocumentTypeMessage');
            } else if (infectedFileMessage) {
                setErrors &&
                    setErrors({
                        file: ERROR_INFECTED_FILE,
                    });
                const attempts = get(data, ['errors', 'attempts'], 0);
                callbackInfectedFileError && callbackInfectedFileError(attempts ? Number(attempts) : null);
                yield put(uploadRequesterCase.error({message: ERROR_INFECTED_FILE}));
                showAlert && showAlert(ALERT_TYPE_ERROR, ERROR_INFECTED_FILE);
            } else {
                setErrors && setErrors(data.errors);
                yield put(uploadRequesterCase.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield put(uploadRequesterCase.error(e.toString()));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchUploadSignature() {
    while (true) {
        const {
            payload: {file, formId, fieldId, caseId, sectionId, callback, showAlert},
        }: IActionType<UploadRequesterSignatureTypes> = yield take(UPLOAD_REQUESTER_SIGNATURE.REQUEST);
        try {
            const formData = new FormData();
            formData.append('media', file, 'signature');
            formData.append('form', String(formId));
            formData.append('section', String(sectionId));
            formData.append('field', String(fieldId));
            const data: RequesterSignatureUploadResponseType = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `case/${caseId}/signature/upload`,
                {
                    method: 'POST',
                    body: formData,
                },
            );

            if (data.success) {
                yield put(
                    uploadRequesterSignature.success({
                        signatures: data.results,
                    }),
                );
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(uploadRequesterCase.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield put(uploadRequesterCase.error(e.toString()));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchDeleteSignature() {
    while (true) {
        const {
            payload: {uuid, caseId, sectionId, toggleSectionChecked, callback},
        }: IActionType<DeleteRequesterSignatureTypes> = yield take(DELETE_REQUESTER_SIGNATURE.REQUEST);
        try {
            // TODO: WHAT RETURNS
            const data: RequesterSignatureUploadResponseType = yield call(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                http,
                `signature/${uuid}/delete`,
                {
                    method: 'delete',
                    body: JSON.stringify({
                        sectionId,
                        caseId,
                    }),
                },
            );

            if (data.success) {
                yield put(
                    deleteRequesterSignature.success({
                        signatures: data.results,
                    }),
                );
                callback && callback();
            } else {
                yield put(deleteRequesterSignature.error({message: 'messages.error.somethingWentWrong'}));
                toggleSectionChecked && toggleSectionChecked();
            }
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield put(profileInfoStep.error(e.toString()));
        }
    }
}

function* watchDeleteProfileEntities() {
    while (true) {
        const {
            payload: {uuid, sectionId, caseId, toggleSectionChecked, callback},
        }: IActionType<DeleteRequesterDocumentTypes> = yield take(DELETE_REQUESTER_DOCUMENT.REQUEST);
        try {
            const data: RequesterDocumentUploadResponseType = yield call(http, `document/${uuid}/delete`, {
                method: 'POST',
                body: JSON.stringify({
                    sectionId,
                    caseId,
                }),
            });
            if (data.success) {
                yield put(
                    deleteRequesterCase.success({
                        files: data.results,
                    }),
                );
                callback && callback();
            } else if (data.errors === ERROR_SECTION_ALREADY_CHECKED) {
                yield put(updateRequesterCase.error({message: ERROR_SECTION_ALREADY_CHECKED}));
                toggleSectionChecked && toggleSectionChecked();
            } else {
                yield put(deleteRequesterCase.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield put(profileInfoStep.error(e.toString()));
        }
    }
}

function* watchEditProfileEntities() {
    while (true) {
        const {
            payload: {uuid, description, sectionId, caseId, toggleSectionChecked, callback, showAlert},
        }: IActionType<EditRequesterDocumentTypes> = yield take(EDIT_REQUESTER_DOCUMENT.REQUEST);
        try {
            const data: RequesterDocumentUploadResponseType = yield call(http, `document/${uuid}/edit`, {
                method: 'POST',
                body: JSON.stringify({
                    sectionId,
                    caseId,
                    description,
                }),
            });
            if (data.success) {
                yield put(
                    editRequesterCase.success({
                        files: data.results,
                    }),
                );
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors === ERROR_SECTION_ALREADY_CHECKED) {
                yield put(editRequesterCase.error({message: ERROR_SECTION_ALREADY_CHECKED}));
                toggleSectionChecked && toggleSectionChecked();
                showAlert && showAlert(ALERT_TYPE_ERROR);
            } else {
                yield put(editRequesterCase.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            yield put(profileInfoStep.error(e.toString()));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRequestCaseAgentAssigning() {
    while (true) {
        const {
            payload: {agentId, caseId, callback, showAlert},
        }: IActionType<RequestCaseAssignAgentType> = yield take(REQUEST_CASE_ASSIGN_AGENT.REQUEST);
        try {
            const data: RequesterCaseResponseTypes = yield call(http, `case/${caseId}/assign`, {
                method: 'POST',
                body: JSON.stringify({
                    agent: agentId,
                }),
            });
            if (data.success) {
                const caseInformation = get(data, ['results', 'case'], {});
                const watchers = get(data, ['results', 'watchers'], []);
                const tasks = get(data, ['results', 'tasks'], []);
                yield put(
                    requestCaseAssignAgent.success({
                        ...caseInformation,
                        watchers,
                        tasks,
                    }),
                );
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
                callback && callback();
            } else {
                yield put(requestCaseAssignAgent.error({message: data.errors}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(requestCaseAssignAgent.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRequestCaseStatusChanging() {
    while (true) {
        const {
            payload: {status, caseId, showAlert, callback},
        }: IActionType<RequestCaseChangeStatusType> = yield take(REQUEST_CASE_CHANGE_STATUS.REQUEST);
        try {
            const data: RequesterCaseResponseTypes = yield call(http, `case/${caseId}/status`, {
                method: 'POST',
                body: JSON.stringify({
                    status: status,
                }),
            });
            if (data.success) {
                const caseInformation = get(data, ['results', 'case'], {});
                const tasks = get(data, ['results', 'tasks'], []);
                const subFormRequests = get(data, ['results', 'subFormRequests'], []);
                yield put(
                    requestCaseChangeStatus.success({
                        ...caseInformation,
                        tasks,
                        subFormRequests,
                    }),
                );
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else if (data.errors) {
                yield put(
                    requestCaseChangeStatus.error({
                        message: data.errors,
                    }),
                );
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            } else {
                yield put(requestCaseChangeStatus.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(requestCaseChangeStatus.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRequestCaseChatActivation() {
    while (true) {
        const {
            payload: {caseId, callback, showAlert},
        }: IActionType<RequestCaseChatActivateRequestTypes> = yield take(REQUEST_CASE_CHAT_ACTIVATE.REQUEST);
        try {
            const data: RequesterCaseTypes = yield call(http, `case/${caseId}/chat`, {
                method: 'POST',
            });
            const caseInformation = get(data, ['case'], {});
            const result = get(data, ['case', 'result'], null);
            const checkedSections = get(data, 'checkedSections', []);
            const files = get(data, 'files', []);
            const chatFiles = get(data, 'chatFiles', []);
            const tags = get(data, 'tags', []);
            const invites = get(data, 'invites', []);
            if (data) {
                yield put(
                    requestCaseChatActivate.success({
                        ...caseInformation,
                        result: JSON.parse(result),
                        checkedSections,
                        files,
                        chatFiles,
                        tags,
                        invites,
                    }),
                );
                callback ? callback() : showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(requestCaseChatActivate.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(requestCaseChatActivate.error({message: String(e)}));
        }
    }
}

function* watchRequestCasePinning() {
    while (true) {
        const {
            payload: {caseId, callback, showAlert},
        }: IActionType<RequestCaseChatActivateRequestTypes> = yield take(REQUEST_CASE_PINNING.REQUEST);
        try {
            const data: RequesterCaseTypes = yield call(http, `case/${caseId}/pin`, {
                method: 'POST',
            });
            const caseInformation = get(data, ['case'], {});
            const result = get(data, ['case', 'result'], null);
            const checkedSections = get(data, 'checkedSections', []);
            const files = get(data, 'files', []);
            const chatFiles = get(data, 'chatFiles', []);
            const tags = get(data, 'tags', []);
            const invites = get(data, 'invites', []);
            if (data) {
                yield put(
                    requestCaseChatActivate.success({
                        ...caseInformation,
                        result: JSON.parse(result),
                        checkedSections,
                        files,
                        chatFiles,
                        tags,
                        invites,
                    }),
                );
                callback ? callback() : showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(requestCasePinning.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(requestCasePinning.error({message: String(e)}));
        }
    }
}

function* watchGetRequesterCasesStatistic() {
    while (true) {
        const {
            payload: {agent, dateFrom, dateTo},
        }: IActionType<RequesterCaseStatisticRequestTypes> = yield take(GET_REQUESTER_CASES_STATISTIC.REQUEST);
        try {
            const data: RequesterCasesStatisticTypes[] = yield call(
                http,
                `cases/statistic?agent=${agent || ''}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
                {
                    method: 'GET',
                },
            );

            if (data) {
                yield put(getRequesterCasesStatistic.success(data));
            } else {
                yield put(getRequesterCasesStatistic.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(getRequesterCasesStatistic.error({message: String(e)}));
        }
    }
}

function* watchRequesterTagAdding() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<RequestCaseTagRequestTypes> = yield take(REQUEST_CASE_ADD_TAG.REQUEST);
        try {
            const data: RequesterCaseTagResponseTypes = yield call(http, `tag/${id}/add`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(addRequesterTag.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(addRequesterTag.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(addRequesterTag.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRequesterTagDeleting() {
    while (true) {
        const {
            payload: {id, callback, showAlert},
        }: IActionType<RequestCaseTagRequestTypes> = yield take(REQUEST_CASE_REMOVE_TAG.REQUEST);
        try {
            const data: RequesterCaseTagResponseTypes = yield call(http, `tag/${id}/remove`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(deleteRequesterTag.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteRequesterTag.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteRequesterTag.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchArchiveGetting() {
    while (true) {
        const {
            payload: {id, callback},
        }: IActionType<RequesterCaseDownloadArchiveTypes> = yield take(REQUEST_CASE_ARCHIVE.REQUEST);
        try {
            const data: string | Blob = yield call(http, `user/case/file/download/${id}`, {
                method: 'GET',
                responseType: 'blob',
            });
            if (data) {
                yield put(requesterArchiveDownload.success(data));
                callback && callback(data);
            } else {
                yield put(requesterArchiveDownload.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(requesterArchiveDownload.error({message: String(e)}));
        }
    }
}

function* watchRequestCaseQuestion() {
    while (true) {
        const {
            payload: {id, readAt, showAlert, callback, toggleOutdatedInfo},
        }: IActionType<RequestCaseQuestionRequestTypes> = yield take(REQUEST_CASE_QUESTION.REQUEST);
        try {
            const data: RequestCaseQuestionResponseTypes = yield call(http, `case/${id}/question`, {
                method: 'POST',
                body: JSON.stringify({
                    readAt,
                }),
            });
            if (data.errors) {
                if (data.errors === ERROR_OUTDATED_INFORMATION) {
                    yield put(requestCaseQuestion.error({message: ERROR_OUTDATED_INFORMATION}));
                    toggleOutdatedInfo && toggleOutdatedInfo();
                } else {
                    yield put(requestCaseQuestion.error({message: 'messages.error.somethingWentWrong'}));
                    showAlert && showAlert(ALERT_TYPE_ERROR);
                }
            } else {
                yield put(requestCaseQuestion.success());
                callback && callback();
            }
        } catch (e) {
            yield put(requestCaseQuestion.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRequestCaseActivities() {
    while (true) {
        const {
            payload: {id, showAlert},
        }: IActionType<RequesterCaseActivitiesRequestTypes> = yield take(REQUEST_CASE_ACTIVITIES.REQUEST);
        try {
            const data: RequesterCaseActivitiesResponseTypes = yield call(http, `case/${id}/activity`, {
                method: 'GET',
            });
            if (data.errors) {
                if (data.errors === ERROR_OUTDATED_INFORMATION) {
                    yield put(getRequestCaseActivities.error({message: ERROR_OUTDATED_INFORMATION}));
                } else {
                    yield put(getRequestCaseActivities.error({message: 'messages.error.somethingWentWrong'}));
                    showAlert && showAlert(ALERT_TYPE_ERROR);
                }
            } else {
                const results: RequesterCaseActivitiesTypes[] = get(data, 'results', []);
                yield put(getRequestCaseActivities.success(results));
            }
        } catch (e) {
            yield put(getRequestCaseActivities.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRequestCaseActivitiesExport() {
    while (true) {
        const {
            payload: {id, showAlert, callback},
        }: IActionType<RequesterCaseActivitiesExportRequestTypes> = yield take(REQUEST_CASE_ACTIVITIES_EXPORT.REQUEST);
        try {
            const data: string | Blob = yield call(http, `case/${id}/activity/export`, {
                method: 'GET',
                responseType: 'blob',
            });
            if (data) {
                yield put(exportRequestCaseActivities.success(data));
                callback && callback(data);
            } else {
                yield put(requesterArchiveDownload.error({message: 'messages.error.somethingWentWrong'}));
            }
        } catch (e) {
            yield put(exportRequestCaseActivities.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRequesterCaseAttachmentsForArchive() {
    while (true) {
        const {
            payload: {id, showAlert, callback},
        }: IActionType<RequesterCaseGettingTypes> = yield take(REQUEST_CASE_ATTACHMENTS_FOR_ARCHIVE.REQUEST);
        try {
            const data: RequesterCaseAttachmentsForArchiveResponseTypes = yield call(http, `case/${id}/attachments`, {
                method: 'GET',
            });
            if (data.errors) {
                yield put(getRequestCaseAttachmentsForArchive.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            } else {
                yield put(getRequestCaseAttachmentsForArchive.success(data.results));
                callback && callback();
            }
        } catch (e) {
            yield put(getRequestCaseAttachmentsForArchive.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRequesterCaseWatcherAdding() {
    while (true) {
        const {
            payload: {id, callback, showAlert, ...values},
        }: IActionType<CaseWatcherCreatingTypes> = yield take(CREATE_CASE_WATCHER.REQUEST);
        try {
            const data: CaseWatcherResponseTypes = yield call(http, `watcher/${id}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createCaseWatcher.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createCaseWatcher.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createCaseWatcher.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRequesterCaseWatcherRemoving() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<CaseWatcherRemovingTypes> = yield take(DELETE_CASE_WATCHER.REQUEST);
        try {
            const data: CaseWatcherResponseTypes = yield call(http, `watcher/${uuid}/delete`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteCaseWatcher.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteCaseWatcher.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteCaseWatcher.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchRequesterCaseEmailRequest() {
    while (true) {
        const {
            payload: {id, showAlert, callback},
        }: IActionType<RequesterCaseEmailRequestTypes> = yield take(REQUESTER_CASE_EMAIL_REQUEST.REQUEST);
        try {
            const data: RequesterCaseEmailResponseTypes = yield call(http, `case/${id}/export/email`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(requesterCaseEmailRequest.success());
                callback && callback(data.result);
            } else {
                yield put(requesterCaseEmailRequest.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(getRequestCaseAttachmentsForArchive.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchInviteAdditionalFormAdding() {
    while (true) {
        const {
            payload: {caseId, callback, showAlert, ...values},
        }: IActionType<InviteAdditionalFormCreateRequestTypes> = yield take(CREATE_ADDITIONAL_FORM_INVITE.REQUEST);
        try {
            const data: InviteAdditionalFormResponseTypes = yield call(http, `case/invite/${caseId}/create`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createAdditionalFormInvite.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createAdditionalFormInvite.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(createAdditionalFormInvite.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchInviteAdditionalFormRemoving() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<InviteAdditionalFormDeleteRequestTypes> = yield take(DELETE_ADDITIONAL_FORM_INVITE.REQUEST);
        try {
            const data: InviteAdditionalFormResponseTypes = yield call(http, `case/invite/${uuid}/remove`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteAdditionalFormInvite.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteAdditionalFormInvite.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteAdditionalFormInvite.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchCaseHasTaskIgnoring() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<CaseHasTaskIgnoringTypes> = yield take(IGNORE_CASE_HAS_TASK.REQUEST);
        try {
            const data: CaseHasTaskResponseTypes = yield call(http, `case/task/${uuid}/ignore`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(ignoreCaseHasTask.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(ignoreCaseHasTask.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(ignoreCaseHasTask.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchSubFormRequestCreation() {
    while (true) {
        const {
            payload: {caseId, callback, showAlert, ...values},
        }: IActionType<InviteAdditionalFormCreateRequestTypes> = yield take(CREATE_SUBFORM_REQUEST.REQUEST);
        try {
            const data: SubFormRequestsResponseTypes = yield call(http, `case/subform/${caseId}/request`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                yield put(createSubFormRequest.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(createSubFormRequest.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(createSubFormRequest.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchSubFormRequestSubmitting() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<SubFormRequestSubmittingRequestTypes> = yield take(SUBMIT_SUBFORM_REQUEST.REQUEST);
        try {
            const data: SubFormRequestsResponseTypes = yield call(http, `case/subform/${uuid}/submit`, {
                method: 'POST',
            });
            if (data.success) {
                yield put(submitSubFormRequest.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(submitSubFormRequest.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(submitSubFormRequest.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchSubFormRequestRemoving() {
    while (true) {
        const {
            payload: {uuid, callback, showAlert},
        }: IActionType<SubFormRequestDeleteRequestTypes> = yield take(DELETE_SUBFORM_REQUEST.REQUEST);
        try {
            const data: SubFormRequestsResponseTypes = yield call(http, `case/subform/${uuid}/remove`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteSubFormRequest.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteSubFormRequest.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR, data.errors);
            }
        } catch (e) {
            yield put(deleteSubFormRequest.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchStatusChangingRequestUpdating() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback, ...values},
        }: IActionType<StatusChangeApproveUpdateRequestType> = yield take(UPDATE_STATUS_CHANGE_APPROVE_REQUEST.REQUEST);
        try {
            const data: RequesterCaseResponseTypes = yield call(http, `status/change/${uuid}/update`, {
                method: 'POST',
                body: JSON.stringify(values),
            });
            if (data.success) {
                const caseInformation = get(data, ['results', 'case'], {});
                yield put(
                    updateStatusChangeApproveRequest.success({
                        ...caseInformation,
                    }),
                );
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(updateStatusChangeApproveRequest.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(updateStatusChangeApproveRequest.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchStatusChangingRequestUnsetting() {
    while (true) {
        const {
            payload: {uuid, showAlert, callback},
        }: IActionType<UnsetStatusChangeApproveRequestType> = yield take(UNSET_STATUS_CHANGE_APPROVE_REQUEST.REQUEST);
        try {
            const data: StatusChangeApproveResponseType = yield call(http, `status/change/${uuid}/unset`, {
                method: 'GET',
            });
            if (data.success) {
                yield put(unsetStatusChangeApproveRequest.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(unsetStatusChangeApproveRequest.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(unsetStatusChangeApproveRequest.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

function* watchAllStatusChangingRequestsRemoving() {
    while (true) {
        const {
            payload: {id, showAlert, callback},
        }: IActionType<DeleteAllStatusChangeApproveRequestType> = yield take(
            DELETE_ALL_STATUS_CHANGE_APPROVE_REQUESTS.REQUEST,
        );
        try {
            const data: StatusChangeApproveResponseType = yield call(http, `status/change/${id}/remove/all`, {
                method: 'DELETE',
            });
            if (data.success) {
                yield put(deleteAllStatusChangeApproveRequests.success(data.results));
                callback && callback();
                showAlert && showAlert(ALERT_TYPE_SUCCESS);
            } else {
                yield put(deleteAllStatusChangeApproveRequests.error({message: 'messages.error.somethingWentWrong'}));
                showAlert && showAlert(ALERT_TYPE_ERROR);
            }
        } catch (e) {
            yield put(deleteAllStatusChangeApproveRequests.error({message: String(e)}));
            showAlert && showAlert(ALERT_TYPE_ERROR);
        }
    }
}

export default [
    fork(watchGetAllRequesterCases),
    fork(watchGetRequesterCase),
    fork(watchCreateRequestCase),
    fork(watchUpdateRequesterCase),
    fork(watchRequesterPreparedStatus),
    fork(watchUpdateCheckedSections),
    fork(watchUploadFile),
    fork(watchUploadSignature),
    fork(watchDeleteSignature),
    fork(watchDeleteProfileEntities),
    fork(watchEditProfileEntities),
    fork(watchRequestCaseAgentAssigning),
    fork(watchRequestCaseStatusChanging),
    fork(watchRequestCaseChatActivation),
    fork(watchRequestCasePinning),
    fork(watchGetRequesterCasesStatistic),
    fork(watchRequesterTagAdding),
    fork(watchRequesterTagDeleting),
    fork(watchArchiveGetting),
    fork(watchRequestCaseQuestion),
    fork(watchRequestCaseActivities),
    fork(watchRequestCaseActivitiesExport),
    fork(watchRequesterCaseAttachmentsForArchive),
    fork(watchRequesterCaseWatcherAdding),
    fork(watchRequesterCaseWatcherRemoving),
    fork(watchRequesterCaseEmailRequest),
    fork(watchStatusChangingRequestUpdating),
    fork(watchStatusChangingRequestUnsetting),
    fork(watchAllStatusChangingRequestsRemoving),
    fork(watchInviteAdditionalFormAdding),
    fork(watchInviteAdditionalFormRemoving),
    fork(watchCaseHasTaskIgnoring),
    fork(watchSubFormRequestCreation),
    fork(watchSubFormRequestSubmitting),
    fork(watchSubFormRequestRemoving),
];
